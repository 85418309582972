import { acceptHMRUpdate, defineStore } from 'pinia';
import { useAssignedAgentStore } from './assigned-agent';

export interface IInsuranceCategory {
  id: number;
  title: string;
  slug: string;
  image: string;
  to: string;
  toReadMore: string;
  content: string;
  active: boolean;
}

export interface IInsuranceCategoryNotFound {
  id: number;
  title: string;
  contactText: string;
  email: string;
  phone: string;
}

export interface IInsuranceCategoryState {
  categories: IInsuranceCategory[];
  categoryNotFound: IInsuranceCategoryNotFound;
}

export const useInsurancesStore = defineStore('insurances', () => {
  const { t } = useI18n();

  const categories = ref([
    {
      id: 1,
      title: t('insurances.education.title'),
      slug: 'nnw-school',
      image: 'education',
      to: t('pages.insuranceEducationVariants'),
      toReadMore: t('pages.insuranceEducationInfo'),
      content: t('insurances.education.content'),
      active: true,
    },
    {
      id: 2,
      title: t('insurances.travel.title'),
      slug: 'travel',
      image: 'travel',
      to: t('pages.insuranceTravelVariants'),
      toReadMore: t('pages.insuranceTravelInfo'),
      content: t('insurances.travel.content'),
      active: true,
    },
    {
      id: 3,
      title: t('insurances.motoassistance.title'),
      slug: 'motoassistance',
      image: 'motoassistance',
      to: '/motoassistance',
      toReadMore: '/motoassistance-informacje',
      content: t('insurances.travel.content'),
      active: true,
    },
    {
      id: 4,
      title: t('insurances.home.title'),
      slug: 'home',
      image: 'home',
      to: t('pages.insuranceHomeVariants'),
      toReadMore: t('pages.insuranceHomeInfo'),
      content: t('insurances.home.content'),
      active: false,
    },
  ]);

  const getCategories = computed(() => {
    const permissions = useAssignedAgentStore().agentPermissions;

    if (permissions) {
      return categories.value.filter(cat => permissions.includes(cat.slug));
    } else {
      return categories.value;
    }
  });

  return { getCategories };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useInsurancesStore, import.meta.hot));
}
