import { storeToRefs } from 'pinia';
import { useAssignedAgentStore } from '~/store/assigned-agent';

export const useCheckAgentCode = () => {
  const agentsStore = useAssignedAgentStore();
  const { agentCode } = storeToRefs(useAssignedAgentStore());

  const check = (to: any, from: any, next: (params: object|void) => void) => {
    if (to.name === 'ref-link') {
      return next();
    }

    // if (to.path.startsWith('/strefa-klienta')) {
    //   return next()
    // }

    if (to.path.startsWith('/strefa-agenta')) {
      return next();
    }

    if (agentCode.value && !to.query.agent_code) {
      next({
        name: to.name,
        query: { ...to.query, agent_code: agentCode.value },
        params: to.params,
      });
    } else if (!agentCode.value && to.query.agent_code) {
      agentsStore.fetchAgent(to.query.agent_code, null)
        .then(() => {
          next();
        });
    } else if (agentCode.value && agentCode.value !== to.query.agent_code) {
      agentsStore.fetchAgent(to.query.agent_code, null)
        .then(() => {
          next();
        });
    } else {
      next();
    }
  };

  return {
    check,
  };
};
