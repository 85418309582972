import { acceptHMRUpdate, defineStore } from 'pinia';
import axios from 'axios';
import {
  nnwSchoolVariantsDefault,
} from '~/store/data/default-variants';
import type { VariantsState } from '~/types/nnw-variants';

export const useNnwSchoolVariantsStore = defineStore('nnw-school-variants', {
  state(): VariantsState {
    return {
      nnwSchoolVariants: nnwSchoolVariantsDefault,
      nnwSchoolVariantsDownloaded: false,
    };
  },
  actions: {
    async getNnwSchoolVariantsFromApi() {
      try {
        const res = await axios.post(`${import.meta.env./api}/v1/variants-data`, {
          category_slug: 'nnw-school',
        });
        this.nnwSchoolVariants = res.data.data;
        this.nnwSchoolVariantsDownloaded = true;
        return Promise.resolve(res);
      } catch (error) {
        console.error(error);
        this.nnwSchoolVariantsDownloaded = false;
        return Promise.reject(error);
      }
    },
  },
  getters: {
    getNnwSchoolVariants: (state: VariantsState) =>
      state.nnwSchoolVariants && state.nnwSchoolVariants,
    isNnwSchoolVariantsDownloaded: (state: VariantsState) =>
      state.nnwSchoolVariantsDownloaded && state.nnwSchoolVariantsDownloaded,

  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNnwSchoolVariantsStore, import.meta.hot));
}
