<template>
  <div v-if="loading" class="site-loading">
    <img src="/assets/img/logo.png" alt="logo" :class="'header__logo--image'">
  </div>
  <router-view v-else v-slot="{ Component }">
    <transition name="fade">
      <component :is="Component" />
    </transition>
  </router-view>
  <CommonGlobalModal />
  <!--  <CommonCookieModal />-->
</template>

<script setup lang="ts">
import { useErrorStore } from '~/store/error';
import { useSuccessStore } from '~/store/success';
import { useAuthStore } from '~/store/auth';
import { useUserStore } from '~/store/user';
import { useCheckAgentCode } from '~/composables/checkAgentCode';
import { useAssignedAgentStore } from '~/store/assigned-agent';
import { useCmsStore } from '~/store/cms-store';

useHead({
  title: 'Unilink - lider ubezpieczeń',
  meta: [
    {
      name: 'description',
      content:
        'U nas znajdziesz ubezpieczenie na życie, nnw szkolne, turystyczne, mieszkaniowe dopasowane do Twoich oczekiwań i potrzeb.',
    },
  ],
});

const { t } = useI18n();
const router = useRouter();
const authStore = useAuthStore();
const errorStore = useErrorStore();
const userStore = useUserStore();
const successStore = useSuccessStore();
const assignedAgentStore = useAssignedAgentStore();

const errorFromReq = computed(() => errorStore.getErrorFromReq);
const isLoggedIn = computed(() => authStore.getLoggedIn);
const userInfo = computed(() => userStore.getUserInfo);
const loading = ref(true);

watch(
  () => errorFromReq.value.status,
  (nV, oV) => {
    if (nV !== oV && nV !== null && nV !== 401 && nV !== 422) {
      router.push(t(`pages.errors.error${nV}`));
    } else if (nV !== oV && nV === 401) {
      authStore.logout();
      userStore.$reset();
      router.push(t('pages.clientLogin'));
    }
  },
);

router.beforeEach((to, from, next) => {
  assignedAgentStore.setBodyVal();
  next();
});

router.beforeEach((to, from, next) => {
  errorStore.$reset();
  successStore.$reset();
  next();
});

router.beforeEach((to, from, next) => {
  useCheckAgentCode().check(to, from, next);
});

router.beforeEach((to, from, next) => {
  useCmsStore().fetchDataFromApi().then(() => {
    loading.value = false;
    next();
  });

  if (isLoggedIn.value && userInfo.value && userInfo.value.name) {
    window.gtagPush({
      event: 'declaration',
      user: {
        user_id: userInfo.value.id,
        login_state: isLoggedIn.value,
        dimension2: 'ukryty@ukryty.pl',
      },
    });
  }

  // if (!isCompanyDataDownloaded.value) {
  //   companyCmsStore
  //     .getCompanyDataFromApi()
  //     .then(() => {
  //       loading.value = false;
  //     })
  //     .catch(() => {
  //       loading.value = false;
  //     })
  //     .finally(() => next());
  // } else {
  //   loading.value = false;
  //   next();
  // }
});
</script>

<style lang="scss">
@import "./assets/sass/main.scss";
</style>
