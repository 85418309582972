{
    "homepage": {
        "offer": "Oferta",
        "insuranceCategories": "Kategorie Ubezpieczeń",
        "yourAgent": "Twój Agent",
        "youDontHaveAgent": "Nie masz jeszcze swojego agenta?",
        "wantToDiscussWithAgent": "Chcesz porozmawiać ze swoim agentem?",
        "writeOrPhoneWithAgent": "- Napisz lub zadzwoń",
        "contactWithUs": "- Skontaktuj się z nami",
        "recommendations": "Rekomendacje",
        "13kClientsTrustedUs": "Zaufało nam 13 500 klientów",
        "news": "Aktualności",
        "checkNews": "Sprawdź bieżące informacje"
    },
    "common": {
        "bestseller": "Bestseller",
        "login": "Zaloguj się",
        "logout": "Wyloguj",
        "register": "Zarejestruj się",
        "register2": "Załóż konto",
        "aboutUs": "O nas",
        "careOfUnilink": "Dbam Unilink",
        "regulations": "Regulamin",
        "privacyPolicy": "Polityka Prywatności",
        "insurance": "Ubezpieczenie",
        "insurances": "Ubezpieczenia",
        "reportEvent": "Zgłoś zdarzenie",
        "contact": "Kontakt",
        "contactForm": "Formularz kontaktowy",
        "contactFormMessageSent": "Dziękujemy za wysłanie formularza.<br/>Skontaktujemy się z Tobą tak szybko jak to będzie możliwe",
        "contactFormGetMoreInfo": "Aby uzyskac więcej informacji na temat produktów ubezpieczeniowych lub wyliczyć ofertę ubezpieczenia, wypełnij poniższy formularz.",
        "contactFormModal": {
            "header": "Informacja",
            "content": "W celu umożliwienia nam kontaktu z Tobą konieczne jest udzielenie zgody marketingowej, na kontakt przy wykorzystaniu preferowanego przez Ciebie środka komunikacji."
        },
        "agentAliasInfo": {
            "header": "Gratulacje!",
            "content": "Twój kod promocyjny został pomyślnie wczytany.",
            "content2": "Od teraz możesz zakupić nasze ubezpieczenia wraz z przydzielonym do nich rabatem. "
        },
        "contactFormSuccess": "Formularz został wysłany",
        "more": "Więcej",
        "expand": "Rozwiń",
        "collapse": "Zwiń",
        "help": "Pomoc",
        "offer": "Oferta",
        "address": "Adres",
        "policyNo": "Nr polisy",
        "insurer": "Opiekun prawny",
        "insured": "Ubezpieczony",
        "dateOfBirth": "Data urodzenia",
        "seeOfferTable": "Zobacz tabelę ofert",
        "offerTable": "Tabela ofert",
        "yearlyPrice": "opłata roczna",
        "discountCode": "Kod zniżkowy",
        "agentCode": "Kod agenta",
        "tenPercent": "10%",
        "from": "od",
        "to": "do",
        "day": "dzień",
        "month": "miesiac",
        "year": "rok",
        "obligatory": "wymagane",
        "approvals*": "* współpracującym z Unilink S.A. ubezpieczycielem jest zakład ubezpieczeń, uprawniony do prowadzenia działalności w zakresie ubezpieczeń majątkowych albo osobowych na terytorium Rzeczpospolitej Polskiej, który zawarł z Unilink S.A. umowę agencyjną na mocy, której Unilink S.A. zobowiązał się do pośredniczenia w zawieraniu lub zawierania w imieniu zakładu ubezpieczeń umów ubezpieczenia. Aktualna lista współpracujących z Unilink S.A. ubezpieczycieli zawarta jest na stronie www.unilink.pl/ubezpieczyciele ",
        "approvals*-statement-info": "Oświadczam, że zapoznałem się z treścią ",
        "approvals*-statement-info-link": "Informacji Administratora Danych Osobowych.",
        "approvals*-statement-info-to": "/assets/files/insurances/Klauzula_informacyjna_k.pdf",
        "google-recaptcha": "Ta strona zabezpieczona jest przez reCAPTCHA od Google <a href=\"https://policies.google.com/privacy\">Polityka prywatności</a> i <a href=\"https://policies.google.com/terms\">Warunki usługi</a>.",
        "unilinkInfo": {
            "headquarters": "Centrala Unilink S.A.",
            "address": "ul. Konstantego I. Gałczyńskiego",
            "houseNr": "2A",
            "zipCode": "05-090",
            "city": "Raszyn",
            "phoneHeadquarters": "+48 22 483 19 19",
            "phoneHotline": "+48 22 250 00 00",
            "email": "biuro&commat;unilink.pl"
        },
        "files": {
            "regulations": "/assets/files/footer/Regulamin_E-Shop_Unilink.pdf",
            "privacyPolicy": "/assets/files/footer/Polityka_prywatnosci_E-Shop_Unilink.pdf"
        }
    },
    "cookiesPolicy": {
        "content1": "Aby świadczyć usługi na najwyższym poziomie, Internetowe serwisy informacyjne Grupy Unilink wykorzystują pliki cookies zapisywane w pamięci przeglądarki. Szczegółowe informacje na temat celu ich używania oraz zmiany ustawień plików cookies znajdują się w ",
        "content2-link": "Polityce prywatności. ",
        "content2-to": "/assets/files/footer/Polityka_prywatnosci_E-Shop_Unilink.pdf",
        "content3": "Dalsze korzystanie z serwisu bez zmiany ustawień dotyczących cookies w przeglądarce oznacza potwierdzenie zapoznania się z powyższymi informacjami i akceptację plików cookies.",
        "button": "Akceptuję"
    },
    "other": {
        "100percent": "100%",
        "available": "do dyspozycji",
        "cooperatingAgents": "współpracujących agentów",
        "agents": "agentów",
        "protectionGuarantee": "gwarancji ochrony",
        "broker": "pośrednika",
        "reportEventInInsuranceAgency": "Zgłoś szkodę online w Towarzystwie Ubezpieczeniowym",
        "latestNews": "Najnowsze artykuły:"
    },
    "button": {
        "buyNow": "Kup teraz",
        "clientPanel": "Strefa klienta",
        "send": "Wyślij",
        "send2": "Prześlij",
        "reportEvent": "Zgłoś szkodę",
        "reportEventOnline": "Zgłoś online",
        "goNext": "Przejdź dalej",
        "goToPayment": "Przejdź do płatności",
        "acceptAndPay": "Akceptuj i zapłać",
        "logoutAndGoBackToHome2": "Wyloguj i wróć do Strony Głównej",
        "goBackToHome": "Wróc na stronę główną",
        "goBackToHome2": "Wróć do Strony Głównej",
        "saveChanges": "Zapisz zmiany",
        "changePassword": "Zmień hasło"
    },
    "links": {
        "readMore": "Czytaj więcej",
        "showOthers": "Zobacz pozostałe",
        "undo": "Cofnij",
        "back": "Wróć",
        "forgotPassword": "Przypomnij hasło",
        "download": "Pobierz"
    },
    "login": {
        "header": "Logowanie",
        "rememberMe": "Zapamiętaj mnie",
        "dontHaveAccount": "Jeśli nie masz jeszcze konta,",
        "forgotPassword": "Przypomnij hasło",
        "forgotPasswordInputInfo": "Wpisz E-mail by uzyskać link resetujący hasło",
        "resetPassword": "Resetuj hasło",
        "resetPasswordInputInfo": "Wpisz nowe hasło",
        "changePassword": "Zmień hasło",
        "verify": {
            "title": "Logowanie - Strefa klienta",
            "subTitle": "Weryfikacja danych",
            "content": "Proszę czekać. Trwa weryfikacja danych użytkownika..."
        }
    },
    "register": {
        "header": "Rejestracja",
        "alreadyHaveAccount": "Masz już konto?",
        "verificationEmailText": "Na Twój adres mailowy, przesłaliśmy link aktywacyjny. Sprawdź skrzynkę i dokończ rejestrację.",
        "verifyEmailLoader": {
            "verifyEmail": "Weryfikacja emaila",
            "verifyEmailIsProcessing": "Proszę czekać. Trwa weryfikacja emaila..."
        }
    },
    "errors": {
        "error404": {
            "header": "404",
            "content": "Strona nie została odnaleziona"
        },
        "error403": {
            "header": "403",
            "content": "Brak uprawnień do tej strony/danych"
        },
        "error409": {
            "header": "409",
            "content": "Konflikt danych - np. email nie zweryfikowany"
        },
        "error500": {
            "header": "500",
            "content": "Krytyczny błąd serwera"
        },
        "error503": {
            "header": "503",
            "content": "Serwer nie odpowiada"
        }
    },
    "agentPanel": {
        "soldInsurances": "Sold Insurances",
        "myPersonalData": {
            "nameAndSurname": "Imie i nazwisko",
            "agentCode": "Kod agenta",
            "rauNumber": "Nr RAU",
            "phoneNumber": "Nr telefonu",
            "email": "E-mail",
            "email_notification": "Powiadomienia e-mail",
            "website": "Strona internetowa",
            "permissions": "Uprawnienia",
            "avatar": "Avatar"
        },
        "refLinks": {
            "refLinks": "Linki referencyjne",
            "homepage": "Strona Główna",
            "nwwSchool": "NWW Szkolne"
        }
    },
    "clientPanel": {
        "myInsurances": "Moje ubezpieczenia",
        "myData": "Moje dane",
        "refLinks": "Linki referencyjne",
        "selectedApprovals": "Wyrażone zgody",
        "insuranceData": "Dane Ubezpieczenia",
        "insurancesList": {
            "addInsurance": "Dodaj ubezpieczenie",
            "type": "Rodzaj",
            "agent": "Agent",
            "payment": "Płatność",
            "actions": "Działania",
            "group": "Grupowe",
            "from": "Od",
            "to": "Do"
        },
        "insuranceDetails": {
            "policyNr": "Polisa nr",
            "basicInfo": "Informacje podstawowe",
            "policyAndInfo": "Polisa i materiały informacyjne",
            "certEmail": "Certyfikat",
            "agentData": "Dane agenta",
            "variant": "Wariant ub.",
            "price": "Składka",
            "signedDate": "Data zawarcia",
            "paymentStatus": "Status płatności",
            "status": "Status",
            "startDate": "Początek ubezpieczenia",
            "endDate": "Koniec ubezpieczenia",
            "person": "Imię i nazwisko",
            "email": "Email",
            "address": "Adres",
            "country": "Państwo",
            "pesel": "PESEL",
            "phone": "Telefon",
            "code": "Kod Agenta",
            "www": "Adres strony",
            "policy": "Polisa",
            "owu": "OWU",
            "productCard": "Karta produktu",
            "clause": "Klauzula informacyjna",
            "documents": [
                {
                    "name": "OWU",
                    "path": "/assets/files/insurances/nnw-school/OWU_NNW_Dzieci_i_Mlodziez_Szkolna_2023.pdf"
                },
                {
                    "name": "Karta produktu",
                    "path": "/assets/files/insurances/nnw-school/IPID_NNW_Dzieci_i_Mlodziez_Szkolna_2023.pdf"
                },
                {
                    "name": "Klauzula informacyna",
                    "path": "/assets/files/insurances/Klauzula_informacyjna_k.pdf"
                }
            ],
            "withoutPesel": "Nie posiadam pesela",
            "dateOfBirth": "Data urodzenia",
            "isCorrespondenceAddress": "Adres do korespondencji aktywny",
            "correspondenceAddress": "Adres do korespondencji",
            "correspondencePost": "Poczta do korespondencji",
            "correspondenceCountry": "Państwo do korespondencji",
            "post": "Poczta",
            "paymentSuccess": "Opłacone",
            "paymentError": "Nie opłacone",
            "paymentPending": "Oczekuje",
            "statusActive": "Aktywna",
            "statusNotActive": "Nie aktywna",
            "statusCancelled": "Anulowana"
        },
        "reportEvent": {
            "header": "Zgłoś szkodę",
            "header2": "Zgłoszenie zdarzenia",
            "headerQuestion": "Czego dotyczy zgłoszenie?",
            "email": "zglos-szkode&commat;signal.pl",
            "phone": "=48 795 868 487"
        },
        "myPersonalData": {
            "changeData": "Zmień dane",
            "changeDataConfirmation": "Dane zostały zmienione",
            "modifyDataDoesNotAffectActualInsurances": "Modyfikacja danych nie wpływa na dane w już zawartych ubezpieczeniach",
            "oldPassword": "Stare hasło",
            "newPassword": "Nowe hasło",
            "repeatNewPassword": "Potwierdź nowe hasło",
            "changePasswordConfirmation": "Hasło zostało zmienione",
            "deleteAccount": {
                "delete": "Usuń konto",
                "deleteConfirmation": "Konto usunięte",
                "content1": "Czy na pewno chcesz usunąć konto?",
                "content2": "Ta operacja jest nieodwracalna.",
                "content3": "Zostaniesz wylogowany(a) z systemu."
            },
            "consents": "Wyrażone zgody"
        }
    },
    "insurances": {
        "insurance": "Ubezpieczenie",
        "education": {
            "title": "NNW Szkolne",
            "content": "Ubezpieczenie szkolne NNW to ubezpieczenie ochronne, dające możliwość uzyskania świadczenia dla dzieci i młodzieży m.in. w związku z nieszczęśliwym wypadkiem.",
            "slug": "nnw-school"
        },
        "travel": {
            "title": "Turystyczne",
            "content": "Oferta w przygotowaniu",
            "slug": "travel"
        },
        "home": {
            "title": "Nieruchomości",
            "content": "Oferta w przygotowaniu",
            "slug": "home"
        },
        "notFound": {
            "title": "Nie znalazłeś interesującej Cię kategorii?",
            "contactWithUs": "Skontaktuj się z nami"
        }
    },
    "insurancesDetails": {
        "education": {
            "categoryTitle": "NWW Szkolne",
            "title": "NNW Szkolne idealne dla Twojego dziecka",
            "contentTitle": "Daj Twojemu dziecku realną ochronę w szkole i poza nią ",
            "content": [
                "15% zniżki na drugie i kolejne dziecko",
                "25% zniżki na ubezpieczenie turystyczne",
                "Ochrona 24h/7 na całym świecie",
                "Wysokie świadczenia za uszczerbek w placówce oświatowej",
                "Pakiet Dziecko w Sieci w zakresie",
                "Ubezpieczenie już od 33 zł"
            ]
        }
    },
    "buyWizardForm": {
        "buyForm": "Formularz zakupu",
        "packetOne": "Pakiet 1",
        "packet": "Pakiet",
        "areYouOurClientAlready": "Jesteś już naszym klientem?",
        "loggedInAs": "Jesteś zalogowany jako:",
        "loginInfo": "Po zalogowaniu autmatycznie wprowadzimy Twoje dane oraz zapiszemy ubezpieczenie na Twoim koncie",
        "discountCodeInfo": "Wprowadź kod pośrednika jeśli go posiadasz, jeżeli nie pozostaw pole puste",
        "insuranceStartDate": "Data rozpoczęcia ochrony ubezpieczeniowej",
        "insurer": "Ubezpiecząjący",
        "insured": "Ubezpieczony",
        "address": "Adres zamieszkania",
        "correspondenceAddress": "Adres do korespondencji",
        "correspondenceAddressActive": "Adres do korespondencji inny niż zamieszkania",
        "sameAddressAsInsurer": "Dane adresowe Ubezpieczonego takie same jak Opiekuna prawnego",
        "chooseInsuranceType": "Wybierz rodzaj ubezpieczenia",
        "chooseInsuranceOffer": "Wybierz ofertę",
        "nextInsured": "Kolejny ubezpieczony",
        "deleteInsured": "Usuń",
        "marketingConsents": "Zgody marketingowe",
        "consentsAndStatements": "Zgody i oświadczenia",
        "consents": "Zgody",
        "statements": {
            "header": "Oświadczenia",
            "content1": "Wszystkie dane zawarte w deklaracji są prawdziwe i zgodne z moją najlepszą wiedzą. Jestem świadomy(a), że SIGNAL IDUNA Polska TU S.A, w przypadku stwierdzenia ich nieprawdziwości, może ograniczyć lub odmówić wypłaty świadczeń określonych w umowie ubezpieczenia, w przypadkach określonych przepisami prawa.",
            "content2": "Wszystkie dane zawarte w deklaracji są prawdziwe i zgodne z moją najlepszą wiedzą. Jestem świadomy(a), że SIGNAL IDUNA Polska TU S.A, w przypadku stwierdzenia ich nieprawdziwości, może ograniczyć lub odmówić wypłaty świadczeń określonych w umowie ubezpieczenia, w przypadkach określonych przepisami prawa.",
            "content3": "Oświadczam, że posiadam regularny dostęp do Internetu oraz wskazanego wyżej we wniosku adresu e-mail i wnoszę o doręczanie wszelkich informacji związanych z zawartą umową ubezpieczenia, w tym reklamacji,, na tenże adres e-mail, o ile umowa ubezpieczenia nie stanowi inaczej.",
            "content4": "Potwierdzam, że przed przystąpieniem do umowy ubezpieczenia, na podany adres e-mail, otrzymałam/em, zapoznałam/em się, zrozumiałam/em oraz akceptuję postanowienia zawarte w Ogólnych Warunkach Ubezpieczenia następstw nieszczęśliwych nypadków dzieci i młodzieży szkolnej i dokumencie zawierającym informacje o produkcie ubezpieczeniowym (IPID).",
            "content5-1": "Oświadczam, że przed przystąpieniem do umowy ubezpieczenia zapoznałem się z informacją o Agencie Ubezpieczeniowym.",
            "content5-2-1": "Oświadczam, że zapoznałem się z ",
            "content5-2-2-link": "Regulaminem świadczenia usług drogą elektroniczną.",
            "content5-2-3": "",
            "content5-3-1": "Oświadczam, że zapoznałem się z treścią ",
            "content5-3-2-link": "Informacji Administratora Danych Osobowych.",
            "content5-3-2-to": "/assets/files/insurances/Klauzula_informacyjna_k.pdf"
        },
        "withoutPesel": "Nie posiadam numeru PESEL",
        "prices": {
            "totalValue": "Łączna składka"
        },
        "emailConsent": "Podając adres e-mail wyrażasz zgodę na przesłanie niezbędnych dokumentów drogą elektroniczną, w szczególności Ogólnych Warunków Ubezpieczenia wraz z Tabelą urazów oraz uszczerbków powstałych wskutek nieszczęśliwego wypadku",
        "phoneConsent": "Podając numer wyrażasz zgodę na kontakt drogą telefoniczną",
        "payment": {
            "choosePaymentMethod": "Wybierz sposób płatności",
            "cardNumber": "Nr karty płatniczej",
            "cardDate": "Data ważności",
            "cardCode": "Kod weryfikacyjny",
            "blikCode": "Kod BLIK",
            "yourChoice": "Twój wybór:",
            "bankPaymentStatement": "Klikając przycisk “Akceptuję i płacę” zostaniesz przeniesiony na stronę logowania do banku,  dane do przelewu oraz kwota zostaną automatycznie uzupełnione. Należy jedynie potwierdzić operację.\n",
            "regulationsConsent": "Akceptuję Regulamin dokonywania płatności. Administratorem danych osobowych jest Krajowy Integrator Płatności spółka akcyjna z siedzibą w Poznaniu. Zapoznaj się z pełną treścią."
        },
        "summary": {
            "insuranceCompany": "Towarzystwo Ubezpieczeniowe",
            "offer": "Oferta",
            "totalValue": "Łącznie do zapłaty dzisiaj",
            "insurancePeriod": "Okres trwania",
            "chosenVariant": "Spośród dostępnych wariantów ubezpieczenia wybrano następujący zakres:",
            "confirmInsurance": "Potwierdzam wolę przystąpienia do umowy ubezpieczenia",
            "company": {
                "name": "SIGNAL IDUNA Polska TU S.A",
                "address": "ul. Siedmiogrodzka 9 ",
                "city": "01-204 Warszawa"
            }
        },
        "namesOfSteps": {
            "step1": "Dane / Oświadczenia",
            "step2": "Podsumowanie / Płatność",
            "step3": "Generowanie polisy"
        },
        "statement": {
            "title": "Oświadczenie",
            "contentTitleOne": "́Powiadomiono mnie o tym, że w ramach wybranego przeze mnie ubezpieczenia:",
            "contentOne": [
                "jego głównym przedmiotem są następstwa nieszczęśliwych wypadków dla dzieci w wieku od 6 miesiąca i młodzieży szkolnej;",
                "wybrane przeze mnie ubezpieczenie obejmuje ochronę udzielaną przez okres 12 miesięcy;",
                "od wysokości opłaty zależy wysokość sumy ubezpieczenia i wysokość wypłaconego świadczenia w przypadku szkody"
            ],
            "contentTitleTwo": "Zapoznałem się z ustandaryzowanym dokumentem zawierającym informacje o produkcie ubezpieczeniowym (Karta Produktu), dokumentem zawierającym informacje o Agencie (DZIA) oraz klauzulą informacyjną dotyczącą przetwarzania danych osobowych:",
            "contentTwo": [
                {
                    "name": "Karta produktu",
                    "path": "/assets/files/insurances/nnw-school/IPID_NNW_Dzieci_i_Mlodziez_Szkolna_2023.pdf"
                },
                {
                    "name": "OWU i errata do warunków ubezpieczenia",
                    "path": "/assets/files/insurances/nnw-school/OWU_NNW_Dzieci_i_Mlodziez_Szkolna_2023.pdf"
                },
                {
                    "name": "Klauzula informacyna",
                    "path": "/assets/files/insurances/Klauzula_informacyjna_k.pdf"
                },
                {
                    "name": "Dokument zawierający informacje o Agencie Ubezpieczeniowym",
                    "path": "/assets/files/insurances/nnw-school/DZIA.pdf"
                },
                {
                    "name": "Pełnomocnictwo",
                    "path": "/assets/files/insurances/nnw-school/PELNOMOCNICTWO_UNILINK.pdf"
                }
            ]
        },
        "certificate": {
            "header": "Dziękujemy za zakup ubezpieczenia",
            "accountHeader": "Nasze ubezpieczenie pomoże Ci chronić to, co dla Ciebie najważniejsze.",
            "downloadCertificate": "Kliknij \"Pobierz\" aby pobrać certyfikat",
            "downloadCertificateLevel2": "Hasło do pliku to PESEL lub data urodzenia dziecka",
            "downloadCertificateLevel3": "(zależne od wprowadzonych danych)",
            "downloadCertificates": "Pobierz certyfikaty"
        },
        "verifyPayment": {
            "header": "Weryfikacja płatności",
            "content": "Proszę czekać. Trwa weryfikowanie płatności...",
            "backToPayment": "Wróć do płatności"
        }
    },
    "reportEvent": {
        "header": "Zgłoś zdarzenie",
        "headerContent": "Chcesz zgłosić zdarzenie online? Nic prostszego:",
        "contentHeader": "Zgłoś szkode za pośrednictwem strony internetowej",
        "contentText1": "Korzystając z Elektronicznego Zgłoszania Szkody na stronie internetowej.",
        "contentWebSite1": "https://www.signal-iduna.pl",
        "contentText2": "w zakładce: Zgłoś szkodę",
        "contentText3": "lub bezpośrednio pod adresem:",
        "contentWebSite2": "https://claims.signal-iduna.pl/formularz-zgloszenia-szkody-majatkowej"
    },
    "inputs": {
        "insuranceStartDate": "DD-MM-RRRR",
        "name": "Imię",
        "surname": "Nazwisko",
        "pesel": "PESEL",
        "dateOfBirth": "Data urodzenia (DD-MM-RRRR)",
        "email": "Email",
        "phone": "Telefon kontaktowy",
        "phoneNumber": "+48 000-000-000",
        "chooseCountry": "Wybierz kraj",
        "chooseCity": "Wybierz miasto",
        "country": "Państwo",
        "city": "Miasto",
        "cityNoOptions": "Wpisz kod pocztowy",
        "zipCode": "Kod pocztowy",
        "post": "Poczta",
        "street": "Ulica",
        "houseNr": "Nr domu",
        "apartmentNr": "Nr mieszkania",
        "searchForInsurance": "Wyszukaj ubezpieczenie",
        "message": "Wiadomość",
        "enterName": "Wprowadź imię",
        "enterPhone": "Wprowadź nr telefonu",
        "enterEmail": "Wprowadź e-mail",
        "enterMessage": "Napisz w czym możemy Ci pomóc",
        "insurerHim": "opiekuna prawnego",
        "insuredHim": "ubezpieczonego",
        "enterCardNumber": "Podaj 16-cyfrowy numer karty",
        "enterCardDate": "Podaj miesiąc i rok",
        "enterCardCode": "CVV2/CVC",
        "enterBlikCode": "Podaj 6-cio cyfrowy kod",
        "selectAllApprovals": "Zaznacz / odznacz wszystkie zgody",
        "password": "Hasło",
        "repeatPassword": "Powtórz hasło",
        "userHim": "użytkownika",
        "statementRadioOption1": "Zgadzam sie",
        "statementRadioOption2": "Nie zgadzam się",
        "approvalRadioOption1": "Tak",
        "approvalRadioOption2": "Nie"
    },
    "validations": {
        "insuranceStartDate": "Podanie daty rozpoczęcia ochrony ubezpieczenia jest wymagane. \nMinimalna data: następny dzień od daty dzisiejszej. \nMaksymalna data: 1-60dni od daty minimalnej",
        "discountCode": "Kod pośrednika jest niepoprawny",
        "name": "To pole jest wymagane. \nLiczba znaków 2-50. \nTylko duże i małe litery.",
        "surname": "To pole jest wymagane. \nLiczba znaków 2-250. \nTylko duże i małe litery oraz znak '-'. ",
        "pesel": "To pole jest wymagane. \nLiczba znaków 11. \nMusi zawierać prawidłowy numer PESEL.",
        "insurerPesel": "To pole jest wymagane. \nLiczba znaków 11. \nMusi zawierać prawidłowy numer PESEL. \nPrzedział wiekowy opiekuna prawnego: 18 - 99 lat\"",
        "insuredPesel": "To pole jest wymagane. \nLiczba znaków 11. \nMusi zawierać prawidłowy numer PESEL. \nPrzedział wiekowy ubezpieczonego: 6 miesięcy - 26 lat",
        "email": "To pole jest wymagane. \nLiczba znaków 5-250. \nMusi zawierać dokładnie jeden znak tzw. małpy, co najmniej jeden znak '.'",
        "insurerDateOfBirth": "Podanie daty urodzenia opiekuna prawnego jest wymagane. \nPrzedział wiekowy opiekuna prawnego: 18 - 99 lat",
        "insuredDateOfBirth": "Podanie daty urodzenia ubezpieczonego jest wymagane. \nPrzedział wiekowy ubezpieczonego: 6 miesięcy - 26 lat",
        "phone": "To pole jest wymagane. \nLiczba znaków: 9. \nTylko cyfry.",
        "street": "To pole jest wymagane. \nLiczba znaków: 2-150.",
        "houseNr": "To pole jest wymagane. \nLiczba znaków: 1-10.",
        "apartmentNr": "Liczba znaków: 1-10.",
        "zipCode": "To pole jest wymagane. \nLiczba znaków: 5. \nTylko cyfry. \nWprowadź poprawny kod pocztowy.",
        "city": "To pole jest wymagane. \nWpisz kod pocztowy",
        "post": "To pole jest wymagane. \nLiczba znaków: 2-50.",
        "country": "To pole jest wymagane. \nProsze wybrać Państwo z panelu.",
        "cardNumber": "To pole jest wymagane. \nLiczba znaków 16. \nTylko cyfry.",
        "cardDate": "To pole jest wymagane. \nLiczba znaków 4. \nFormat MM/RR.",
        "cardCode": "To pole jest wymagane. \nLiczba znaków 3. \nTylko cyfry.",
        "blikCode": "To pole jest wymagane. \nLiczba znaków 6. \nTylko cyfry.",
        "selectRequiredApproval": "Zaznacz wymaganą zgodę",
        "badDateOfBirth": "Wybrana data urodzenia jest nieprawdłowa",
        "badDateOfInsurance": "Wybrana data początku ubezpieczenia jest nieprawidłowa",
        "badPesel": "Podany numer PESEL jest nieprawidłowy",
        "badPeselwithDates": "Podany numer PESEL jest nieprawidłowy (wiek osoby powinien zawierać się w przedziale od {minAge} do {maxAge} lat)",
        "message": "To pole jest wymagane. \nLiczba znaków: 20-1500.",
        "password": "To pole jest wymagane. Liczba znaków: 8-24. Musi zawierać minimun 1 mała literę, 1 wielką literę i 1 znak specjalny ({'@'}$!%*#?&).",
        "samePassword": "Obydwa hasła muszą być identyczne.",
        "validationFrameText": "Popraw oznaczone pola",
        "validationFrameText2": "(lub wypełnij wymagane)",
        "serverErrorOccurred": "Błąd (błędy) ze strony serwera:"
    },
    "currencies": {
        "zloty": "zł",
        "pln": "zł"
    },
    "refLink": {
        "title": "Link referencyjny",
        "subTitle": "Weryfikacja linku",
        "content": "Proszę czekać. Trwa weryfikacja linku referencyjnego..."
    },
    "files": {
        "title": "Plik",
        "subTitle": "Weryfikacja linku",
        "content": "Proszę czekać. Trwa weryfikacja linku do pliku..."
    },
    "redirect": {
        "title": "Przekierowanie",
        "subTitle": "Przekierowanie",
        "content": "Proszę czekać. Trwa przekierowanie do strony docelowej..."
    },
    "news": {
        "loading": {
            "title": "Aktualności",
            "subTitle": "Wczytywanie zawartości",
            "content": "Proszę czekać. Trwa wczytywanie zawartości aktualności..."
        },
        "infiniteLoading": {
            "errorContent": "Wystąpił błąd podczas ściagania aktualności.",
            "errorButtonContent": "Spróbuj jeszcze raz"
        }
    },
    "pages": {
        "home": "/",
        "insuranceEducationVariants": "/nnw-szkolne",
        "insuranceEducationInfo": "/nnw-szkolne-informacje",
        "insuranceEducationForm": "/nnw-szkolne/formularz",
        "verifyPayment": "/weryfikacja-platnosci",
        "reportEvent": "/zglos-szkode",
        "reportEventEducation": "/zglos-szkode/nnw-szkolne",
        "news": "/aktualnosci",
        "clientLogin": "/strefa-klienta",
        "clientRegister": "/strefa-klienta/rejestracja",
        "forgotPassword": "/strefa-klienta/przypomnij-haslo",
        "resetPassword": "/strefa-klienta/zresetuj-haslo",
        "verifyEmail": "/strefa-klienta/verify-email/:id/:hash",
        "clientMyPersonalData": "/strefa-klienta/moje-dane",
        "clientListOfMyInsurances": "/strefa-klienta/moje-ubezpieczenia",
        "clientInsuranceDetails": "/strefa-klienta/moje-ubezpieczenia",
        "clientYourAgent": "/strefa-klienta/twoj-agent",
        "agentMyPersonalData": "/strefa-agenta/moje-dane",
        "agentListOfMyInsurances": "/strefa-agenta/moje-ubezpieczenia",
        "agentInsuranceDetails": "/strefa-agenta/moje-ubezpieczenia",
        "agentRefLinks": "/strefa-agenta/linki-referencyjne",
        "contact": "/kontakt",
        "regulations": "/regulamin",
        "privacyPolicy": "/polityka-prywatnosci",
        "notFound": "/notfound404",
        "insurancesDynamic": "/ubezpieczenia",
        "newsArticleDynamic": "/aktualnosci/artykul",
        "buyInsuranceVariant": "/kup-ubezpieczenie-wariant",
        "buyInsurance": "/kup-ubezpieczenie",
        "insuranceTravelVariants": "/turystyczne",
        "insuranceTravelInfo": "/turystyczne-informacje",
        "insuranceTravelForm": "/turystyczne/formularz/",
        "insuranceHomeVariants": "/nieruchomosci",
        "insuranceHomeInfo": "/nieruchomosci-informacje",
        "insuranceHomeForm": "/nieruchomosci/formularz/",
        "errors": {
            "error403": "/brak-uprawnien",
            "error404": "/strona-nie-znaleziona",
            "error409": "/konflikt",
            "error500": "/krytyczny-blad-serwera",
            "error503": "/serwer-nie-odpowiada"
        }
    },
    "insuranceVariants": {
        "type": "Rodzaj ubezpieczenia",
        "range": "Zakres ubezpieczenia",
        "bestseller": "Bestseller",
        "variant": "Wariant",
        "sum": "Suma ub.",
        "education": {
            "bestsellerIndex": "3",
            "bestsellerVariant": "variant-4",
            "name": "Ubezpieczenia NWW Szkolne",
            "types": [
                "Szkoła sportowa / mundurowa"
            ],
            "parameters": [
                {
                    "name": "Szkoła sportowa / mundurowa",
                    "parameter": "sport-school"
                }
            ],
            "typesParameters": [
                "sport-school"
            ]
        },
        "variants": [
            37,
            57,
            77,
            107,
            157,
            277
        ]
    },
    "travel": {
        "add": "Dodaj",
        "buyNow": "Kup teraz",
        "persons": {
            "child": "Dzieci",
            "adult": "Osoby dorosłe",
            "senior": "Seniorzy"
        },
        "person": {
            "child": "Dziecko",
            "adult": "Osoba dorosła",
            "senior": "Senior"
        },
        "extras": {
            "ContinuationTreatment": "Kontynuacja leczenia w PL",
            "ManualWork": "Wyjazd do pracy - praca fizyczna ",
            "Sports": "Sporty wysokiego ryzyka",
            "SkiPackage": "Pakiet Narty"
        },
        "variants": {
            "names": {
                "Excelent": "Zdobywca",
                "Komfort": "Podróżnik",
                "Reference": "Urlopowicz"
            },
            "data": {
                "chooseInsurance": "Wybierz ubezpieczenie",
                "options": {
                    "who": {
                        "label": "Chcę wykupić ubezpieczenie turystyczne",
                        "options": {
                            "Self": "dla siebie",
                            "Others": "dla innych osób",
                            "Family": "dla rodziny",
                            "Group": "grupowe"
                        }
                    }
                }
            },
            "range": "Zakres ubezpieczenia",
            "bestseller": "Bestseller",
            "choose": "Wybierz",
            "chosen": "Wybrano"
        },
        "form": {
        }
    }
}

