<template>
  <div class="your-agent-card">
    <img
      v-if="displayLogo && !displayPartnerLogo"
      class="your-agent-card__logo"
      src="@/assets/img/logo_footer.png"
      alt="u"
      loading="lazy"
    >
    <img
      v-if="displayLogo && displayPartnerLogo"
      class="your-agent-card__logo"
      :src="assignedAgentStore.agent?.image"
      alt="partner logo"
      loading="lazy"
    >

    <div class="your-agent-card__names">
      <span>{{ data.name }}</span>
      <span>{{ data.last_name }}</span>
    </div>

    <div v-if="data.phone" class="your-agent-card__content-website your-agent-card__content">
      <div v-if="!state.showPhone">
        <a href="#" class="text-secondary text-no-decoration">+48 XXX XXX XXX </a>
        <span class="text-no-decoration text-primary text-bold text-small cursor-pointer margin-left-1" @click="state.showPhone=true">Pokaż</span>
      </div>
      <a v-else :href="`tel:${data.phone}`" class="display-block text-secondary text-no-decoration">
        {{ data.phone.startsWith('+') ? '' : '+48' }} {{ data.phone }}
      </a>
    </div>
    <div class="your-agent-card__content-website your-agent-card__content">
      <div v-if="!state.showEmail">
        <a href="#" class="text-secondary text-no-decoration">xxxxxxxx@unilink.pl</a>
        <span class="text-no-decoration text-primary text-bold text-small cursor-pointer margin-left-1" @click="state.showEmail=true">Pokaż</span>
      </div>
      <a v-else :href="`mailto:${data.email}`" class="display-block text-secondary text-no-decoration">{{ data.email }}</a>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IAgent } from '~/store/assigned-agent'
import { useAssignedAgentStore } from '~/store/assigned-agent'

const assignedAgentStore = useAssignedAgentStore()

interface Props {
  data: IAgent
}

const props = withDefaults(defineProps<Props>(), {})
const { data } = toRefs(props)

const state = reactive<{
  showPhone: boolean
  showEmail: boolean
}>({
  showPhone: false,
  showEmail: false,
})

const displayLogo = computed(() => !(assignedAgentStore.agent?.id && assignedAgentStore.agent.hide_all_logotype))
const displayPartnerLogo = computed(() => assignedAgentStore.agent?.id && assignedAgentStore.agent.show_partner_logotype && assignedAgentStore.agent.image.length)
</script>
