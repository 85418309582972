<template>
  <div
    class="bvt-offer-table-container"
    :class="{ 'bvt-offer-table-container--in-modal': inModal }"
  >
    <div
      v-if="
        (scrollY > 900 && windowWidth <= xl) ||
        (scrollY > 900 && windowWidth <= lg && pixelRatio > 1)
      "
      class="bvt-offer-table--sticky-background-helper"
      :class="{ 'bvt-offer-table--sticky-background-helper-in-modal': inModal }"
    />
    <div
      :class="[
        (windowWidth <= xl && pixelRatio !== 1.25 && pixelRatio !== 1.1) ||
        (windowWidth < lg && pixelRatio > 1)
          ? 'bvt-offer-table__header-mobile'
          : 'bvt-offer-table',
        {
          'bvt-offer-table':
            (windowWidth <= xl && windowWidth > lg && pixelRatio === 1.1) ||
            (windowWidth <= xl && windowWidth > lg && pixelRatio === 1.25),
        },
        { 'bvt-offer-table--sticky': scrollY > 666 },
        {
          'bvt-offer-table--sticky-low-z-index':
            scrollY > 3900 ||
            (666 > scrollY < 3900 && windowWidth < xl) ||
            scrollY > 3900 ||
            (666 > scrollY < 3900 && windowWidth < lg && pixelRatio > 1),
        },
        { 'bvt-offer-table--sticky-in-modal': inModal && scrollY > 666 },
        { 'bvt-offer-table--in-modal': inModal },
        {
          'bvt-offer-table__header-mobile--in-modal':
            (windowWidth <= xl && inModal) ||
            (windowWidth <= lg && inModal && pixelRatio > 1),
        },
      ]"
    >
      <div
        v-if="
          (lg < windowWidth <= xl && pixelRatio === 1.1) ||
          (windowWidth <= xl && windowWidth > lg && pixelRatio === 1.25) ||
          windowWidth > xl
        "
        class="bvt-offer-table__name bvt-offer-table__name--header"
      >
        <span class="text-bold"> {{ t("insuranceVariants.range") }} </span>
      </div>
      <div
        v-for="(variant, index) in variants"
        :key="index"
        class="bvt-offer-table__value bvt-offer-table__value--header"
        :class="[
          {
            'bvt-offer-table__value--active':
              (windowWidth <= xl &&
                index === mobileVariant &&
                pixelRatio !== 1.25 &&
                pixelRatio !== 1.1) ||
              (windowWidth <= lg && index === mobileVariant && pixelRatio > 1),
          },
          {
            'bvt-offer-table__value--not-active':
              (windowWidth <= xl &&
                index !== mobileVariant &&
                pixelRatio !== 1.25 &&
                pixelRatio !== 1.1) ||
              (windowWidth <= lg && index !== mobileVariant && pixelRatio > 1),
          },
          {
            'bvt-offer-table__value--bestseller':
              (windowWidth > xl && checkBestseller(index)) ||
              (windowWidth > lg && checkBestseller(index) && pixelRatio > 1),
          },
          { 'bvt-offer-table__value--disabled': handleDisabledTypes(index) },
          {
            'bvt-offer-table__value--header-no-discount':
              (windowWidth > xl && !discountMode) ||
              (windowWidth > lg && !discountMode && pixelRatio > 1),
          },
        ]"
        @click="
          (windowWidth <= xl &&
            !disabledTypes.includes(index) &&
            handleMobileVariant(index)) ||
            (windowWidth <= lg &&
              !disabledTypes.includes(index) &&
              handleMobileVariant(index) &&
              pixelRatio > 1)
        "
      >
        <button
          v-if="
            (windowWidth > xl && buttons) ||
            (windowWidth > lg && buttons && pixelRatio === 1.1) ||
            (windowWidth > lg && buttons && pixelRatio === 1.25)
          "
          class="button button--primary button--radius button--buy-variants"
          :disabled="handleDisabledTypes(index)"
          @click="handleChosenVariant(variant)"
        >
          <span class="text-bold text-normal">{{ t("button.buyNow") }}</span>
        </button>

        <div
          v-if="windowWidth > xl || (windowWidth > lg && pixelRatio > 1)"
          :class="{ 'bvt-offer-table__value--bestseller-label': checkBestseller(index) }"
        >
          <span
            v-if="checkBestseller(index)"
            class="text-extra-small text-extra-bold text-primary letter-spacing-6-percent"
          >
            {{ t("common.bestseller") }}
          </span>
        </div>

        <img
          v-if="
            (windowWidth <= xl &&
              checkBestseller(index) &&
              pixelRatio !== 1.1 &&
              pixelRatio !== 1.25) ||
            (windowWidth <= lg && checkBestseller(index) && pixelRatio > 1)
          "
          src="@/assets/img/icons/star.svg?url"
          alt="bestseller"
          class="bvt-offer-table__value--header-star"
        />

        <div>
          <span
            v-if="
              (windowWidth > xl &&
                discountCodeActivated &&
                !handleDisabledTypes(index)) ||
              (windowWidth > lg &&
                discountCodeActivated &&
                !handleDisabledTypes(index) &&
                pixelRatio > 1)
            "
            class="text-primary"
          >
            <span class="text-semi-bold"> {{ t("common.from") }} </span>
            <span data-cy="discounted-price" class="text-extra-bold text-big">
              {{ variant.discounted_price_formatted }}</span
            >
            <span class="text-semi-bold"> {{ t("currencies.zloty") }} </span>
          </span>
        </div>

        <div
          v-if="windowWidth > xl || (windowWidth > lg && pixelRatio > 1)"
          class="bvt-offer-table__value--header-prices"
        >
          <span
            :class="{
              'text-line-through-primary-solid-2px':
                discountCodeActivated && !handleDisabledTypes(index),
            }"
          >
            <span class="text-bold"> {{ t("common.from") }} &nbsp;</span>
            <span data-cy="price" class="text-bold text-big">
              {{ variant.base_price_formatted }}
            </span>
            <span class="text-bold"> {{ t("currencies.zloty") }}</span>
          </span>
          <span class="text-extra-small"> {{ t("common.yearlyPrice") }} </span>
        </div>

        <span v-else class="bvt-offer-table__value--header-prices">
          <span class="text-bold bvt-offer-table__value--hp-text">
            {{ t("common.from") }}
          </span>
          <span
            v-if="discountCodeActivated && !handleDisabledTypes(index)"
            class="text-bold text-secondary bvt-offer-table__value--hp-discount-text"
          >
            {{ variant.discounted_price_formatted }}
          </span>
          <span
            class="text-bold bvt-offer-table__value--hp-text"
            :class="{
              'text-line-through-primary-solid-2px':
                discountCodeActivated && !handleDisabledTypes(index),
            }"
          >
            {{ variant.base_price_formatted }}
          </span>
          <span class="text-bold bvt-offer-table__value--hp-text">
            {{ t("currencies.zloty") }}/{{ t("common.year") }}</span
          >
        </span>
      </div>
      <div
        v-if="
          (windowWidth <= xl && pixelRatio !== 1.1 && pixelRatio !== 1.25) ||
          (windowWidth <= lg && pixelRatio > 1) ||
          (windowWidth <= xl && pixelRatio === 2)
        "
        class="bvt-offer-table__header-mobile--more"
      >
        <div
          v-if="windowWidth <= xl || (windowWidth <= lg && pixelRatio > 1)"
          :class="{
            'bvt-offer-table__value--bestseller-label': checkBestseller(mobileVariant),
          }"
        >
          <span
            v-if="checkBestseller(mobileVariant)"
            class="text-extra-small text-extra-bold text-primary letter-spacing-6-percent"
          >
            {{ t("common.bestseller") }}
          </span>
        </div>

        <div class="bvt-offer-table__header-mobile--more-variant">
          <span class="text-bold">
            {{ t("insuranceVariants.variant") }}
            {{ t("common.from") }}&nbsp;
            {{
              discountCodeActivated
                ? variants[mobileVariant].discounted_price_formatted
                : variants[mobileVariant].base_price_formatted
            }}
            {{ t("currencies.zloty") }}
          </span>
          <button
            v-if="buttons"
            class="button button--primary button--radius button--buy-variants"
            :disabled="handleDisabledTypes(mobileVariant)"
            @click="handleChosenVariant(variants[mobileVariant])"
          >
            <span class="text-bold text-normal">{{ t("button.buyNow") }}</span>
          </button>
        </div>

        <div class="bvt-offer-table__header-mobile--more-flex">
          <span class="text-bold">{{ t("insuranceVariants.range") }}</span>
          <span class="text-bold">{{ t("insuranceVariants.sum") }}</span>
        </div>
      </div>
    </div>

    <div
      v-if="windowWidth > xl"
      class="bvt-offer-table"
      :class="{ 'bvt-offer-table--in-modal': inModal }"
    >
      <div class="bvt-offer-table__name">
        <div
          v-for="(value, index) in data.nameAndDesc"
          :key="index"
          class="bvt-offer-table--fixed-height"
        >
          <span
            class="text-semi-bold"
          >{{ value.name }}
            <img
              v-tippy="{ trigger: 'click' }"
              src="@/assets/img/icons/form-information.svg?url"
              alt="start"
              :content="value.description"
            />
          </span>
        </div>
      </div>
      <div
        v-for="(variantValue, variantIndex) in data.variantValues"
        :key="variantIndex"
        class="bvt-offer-table__value"
        :class="[
          { 'bvt-offer-table__value--bestseller': checkBestseller(variantIndex, true) },
          { 'bvt-offer-table__value--disabled': handleDisabledTypes(variantIndex) },
        ]"
      >
        <div
          v-for="value in variantValue"
          :key="value.id"
          class="bvt-offer-table--fixed-height"
        >
          <span class="text-semi-bold">{{ value }}</span>
        </div>
      </div>
    </div>

    <div
      v-if="
        (windowWidth <= xl && pixelRatio !== 1.25 && pixelRatio !== 1.1) ||
        (windowWidth <= lg && pixelRatio > 1)
      "
      class="bvt-offer-table"
    >
      <div class="bvt-offer-table__name">
        <div
          v-for="(value, mobileNameAndDescIndex) in data.nameAndDesc"
          :key="mobileNameAndDescIndex"
          class="bvt-offer-table--fixed-height"
        >
          <span class="text-semi-bold">{{ value.name }}</span>
          <img
            v-tippy="{ trigger: 'click' }"
            src="@/assets/img/icons/form-information.svg?url"
            alt="start"
            :content="value.description"
          />
        </div>
      </div>
      <div v-if="typeof mobileVariant !== 'undefined'" class="bvt-offer-table__value">
        <div
          v-for="(value, mobileValueIndex) in getMobileVariantData()"
          :key="mobileValueIndex"
          class="bvt-offer-table--fixed-height"
        >
          <span class="text-semi-bold">{{ value }}</span>
        </div>
      </div>
    </div>
    <div v-if="windowWidth < lg" class="buy-variants__mobile-bottom-area">
      <button
        v-if="buttons"
        class="button button--primary button--radius button--buy-variants"
        :disabled="handleDisabledTypes(mobileVariant)"
        @click="handleChosenVariant(variants[mobileVariant])"
      >
        <span class="text-bold text-normal">{{ t("button.buyNow") }}</span>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useWindowScrollY, useWindowWidth } from '~/composables';
import { useBuyWizardFormStore } from '~/store/buy-wizard-form';
import { useNnwSchoolVariantsStore } from '~/store/nnwSchoolVariants';

interface Props {
  discountMode?: boolean;
  disabledMode?: boolean;
  buttons?: boolean;
  types?: boolean[];
  inModal?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  discountMode: false,
  disabledMode: false,
  buttons: false,
  inModal: false,
  types: () => [false],
});
const { discountMode, disabledMode, buttons, types, inModal } = toRefs(props);

const emit = defineEmits(['chosenVariant']);

const { windowWidth, xl, lg } = useWindowWidth();
const { scrollY } = useWindowScrollY();
const { pixelRatio } = useDevicePixelRatio();

const variantsStore = useNnwSchoolVariantsStore();
const data = computed(() => variantsStore.getNnwSchoolVariants);

const { t } = useI18n();

const mobileVariant = ref(0);
const handleMobileVariant = (index: number) => {
  mobileVariant.value = index;
};

const disabledTypes = ref([]);
const handleDisabledTypes = (index: number) => {
  if (disabledMode.value) {
    const disabled: number[] = [];
    types.value.forEach((v, i) => {
      if (v === true) {
        disabled.push(i);
      }
    });
    disabledTypes.value = disabled;
    return disabled.includes(index);
  } else {
    return false;
  }
};

const checkBestseller = (index, isValue = false) => {
  if (isValue) {
    return index === t('insuranceVariants.education.bestsellerVariant');
  } else {
    return Number(index) === Number(t('insuranceVariants.education.bestsellerIndex'));
  }
};

watch(types, () => {
  handleDisabledTypes(0);
});

const BuyWizardFormStore = useBuyWizardFormStore();
const variants = computed(() => BuyWizardFormStore.getVariantsByIndex(0));
const discountCodeActivated = computed(() => BuyWizardFormStore.getDiscountCodeActivated);

const handleChosenVariant = (variant) => {
  emit('chosenVariant', variant);
};

const getMobileVariantData = () => {
  return data.value.variantValues[`variant-${mobileVariant.value + 1}`];
};

onBeforeMount(() => {
  variantsStore.getNnwSchoolVariantsFromApi();
});
</script>
