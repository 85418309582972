<template>
  <div v-if="agentCategories.length >= 1" class="insurance-categories">
    <CategoriesCard
      v-for="(category, index) in agentCategories"
      :key="index"
      :data="category"
      active
    />
    <CategoriesCard
      v-for="(inactiveCategory, inactiveCategoryIndex) in inactiveCategories"
      :key="inactiveCategoryIndex"
      :data="inactiveCategory"
      :active="false"
    />
    <CategoriesInfoCard
      v-for="(infoCategory, infoCategoryIndex) in informationCategories"
      :key="infoCategoryIndex"
      :data="infoCategory"
    />
  </div>
</template>

<script setup lang="ts">
import { useAssignedAgentStore } from '~/store/assigned-agent';
import { useCmsStore } from '~/store/cms-store';
import type { CmsHomepageContent } from '~/types/cms';
const homepageData = useCmsStore().getData('homepage') as CmsHomepageContent;

const agentCategories = computed(() => {
  const permissions = useAssignedAgentStore().agentPermissions;
  if (permissions) {
    return homepageData?.offer_cards.filter(card => permissions.includes(card.slug));
  } else {
    return homepageData?.offer_cards;
  }
});
const inactiveCategories = homepageData?.inactive_offer_cards && homepageData.inactive_offer_cards;
const informationCategories = homepageData?.information_offer_card && homepageData.information_offer_card;
</script>
