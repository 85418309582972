export const nnwSchoolVariantsDefault = {
  nameAndDesc: [],
  variantValues: {
    'variant-1': [],
    'variant-2': [],
    'variant-3': [],
    'variant-4': [],
    'variant-5': [],
    'variant-6': [],
  },
};

export const travelVariantsDefault = {
  nameAndDesc: [],
  variantValues: {
    Excelent: [],
    Komfort: [],
    Reference: [],
  },
};

export const motoassistanceVariantsDefault = {
  nameAndDesc: [],
  variantValues: {
    standard: [],
    comfort: [],
    premium: [],
  },
};
