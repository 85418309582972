<template>
  <nav class="nav">
    <img
      v-if="windowWidth < lg"
      v-click-outside="toggleMobileFromOutsideMenu"
      class="toggle"
      src="@/assets/img/icons/nav-menu-mobile.svg?url"
      alt="menu mobile"
      @click="toggleMobile"
    >
    <ul
      class="menu"
      :class="[
        { menu__display: isMobile },
        { 'menu__display--fixed': isMobile && scrollY > 50 },
      ]"
    >
      <li data-cy="menu-insurances">
        <span
          ref="insuranceCategoriesMobile"
          class="menu__link normal-menu insurances-mobile-menu"
          :class="{
            'menu__link--active': isRouteStartsWith(
              t('pages.insuranceEducationVariants')
            ),
          }"
          @click="toggleInsuranceCategoriesMobile"
        >{{ t("common.insurances") }}</span>
        <ul
          :class="[
            { display: isInsuranceCategoriesMobile },
            { 'menu__display--fixed': windowWidth > 768 && scrollY > 50 },
          ]"
        >
          <div v-for="(insCat, index) in categories" :key="insCat.id">
            <RouterLink
              v-if="insCat.active"
              :data-cy="`submenu-insurance-${index}`"
              :to="insCat.to"
              class="menu__link menu__link--category"
              :class="{ 'menu__link--active': isRouteStartsWith(insCat.to) }"
              @click="toggleMobile"
            >
              {{ insCat.title }}
            </RouterLink>
          </div>
        </ul>
      </li>
      <li
        :class="{ 'padding-top-zero': isInsuranceCategoriesMobile }"
        data-cy="menu-report-accident"
      >
        <RouterLink
          :to="t('pages.reportEvent')"
          class="menu__link normal-menu"
          :class="{ 'menu__link--active': isRouteStartsWith(t('pages.reportEvent')) }"
          @click="toggleMobile"
        >
          {{ t("common.reportEvent") }}
        </RouterLink>
      </li>
      <li data-cy="menu-contact">
        <RouterLink
          :to="t('pages.contact')"
          class="menu__link"
          :class="{ 'menu__link--active': isRouteStartsWith(t('pages.contact')) }"
          @click="toggleMobile"
        >
          {{ t("common.contact") }}
        </RouterLink>
      </li>
      <li v-if="windowWidth < lg">
        <RouterLink
          :to="t('pages.clientLogin')"
          class="menu__link"
          :class="{
            'menu__link--active':
              isRouteStartsWith(t('pages.clientLogin')) ||
              isRouteStartsWith(t('pages.clientRegister')),
          }"
          @click="toggleMobile"
        >
          {{ t("button.clientPanel") }}
        </RouterLink>
      </li>
    </ul>

    <ul
      class="menu menu--client-contact"
      :class="[
        { menu__display: isMobile },
        { 'menu__display--client-contact': isMobile },
        { 'menu__display--client-contact-fixed': isMobile && scrollY > 50 },
      ]"
    >
      <li data-cy="menu-client-panel">
        <button
          class="button button--secondary-outline button--radius button--menu-link"
          :class="{ 'button--menu-link-fixed': scrollY > 50 }"
          @click="handleRedirectToLogin"
        >
          <span class="text-bold letter-spacing-6-percent">
            {{ t("button.clientPanel") }}
          </span>
        </button>
      </li>
      <li
        v-if="!(assignedAgentStore.agent?.id && assignedAgentStore.agent.hide_address)"
        class="menu__contact"
      >
        <a :href="`tel:${displayedPhone}`" class="menu__contact--phone">
          {{ displayedPhone }}
        </a>
        <a :href="`mailto:${displayedEmail}`" class="menu__contact--email">
          {{ displayedEmail }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { useAssignedAgentStore } from '~/store/assigned-agent';

import { useInsurancesStore } from '~/store/insurances';
import { useCmsStore } from '~/store/cms-store';
import type { CmsDaneFirmyContent } from '~/types/cms';

import {
  isRouteStartsWith,
  toggleMobileFromOutside,
  useWindowScrollY,
  useWindowWidth,
} from '~/composables';

const assignedAgentStore = useAssignedAgentStore();
const insurancesStore = useInsurancesStore();
const { scrollY } = useWindowScrollY();
const { windowWidth, lg } = useWindowWidth();
const router = useRouter();
const { t } = useI18n();

const companyData = useCmsStore().getData('dane-firmy') as CmsDaneFirmyContent;

const isMobile = ref(false);

const isInsuranceCategoriesMobile = ref(false);

const categories = computed(() => insurancesStore.getCategories);

const toggleInsuranceCategoriesMobile = () => {
  if (windowWidth.value < lg.value) {
    isInsuranceCategoriesMobile.value = !isInsuranceCategoriesMobile.value;
  }
};

const toggleMobile = () => {
  if (windowWidth.value < lg.value) {
    isMobile.value = !isMobile.value;
  }
};

const toggleMobileFromOutsideMenu = ($event: any) => {
  toggleMobileFromOutside($event, windowWidth, lg, isMobile);
};

const handleRedirectToLogin = () => {
  router.push(t('pages.clientLogin'));
};

// ---------------------------------------
// AGENT/UNILINK DISPLAYED DATA
// ---------------------------------------

const displayedPhone = computed(() => {
  if (assignedAgentStore.agent?.show_partner_contact_data) {
    return assignedAgentStore.agent.contact_data.agent_hotline;
  } else {
    return companyData.customer_phone;
  }
});

const displayedEmail = computed(() => {
  if (assignedAgentStore.agent?.show_partner_contact_data) {
    return assignedAgentStore.agent.contact_data.agent_email;
  } else {
    return companyData.company_email;
  }
});
</script>
