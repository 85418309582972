<template>
  <div class="bwf-offer-table-layout">
    <div class="bwf-offer-table-layout__header">
      <span class="bwf-offer-table-layout__header--text">{{ t('insuranceVariants.education.name') }}</span>
      <button class="bwf-offer-table-layout__header--close" @click="closeModal">
        <img
          class="validation__input--icon validation__input--icon-error"
          src="@/assets/img/icons/form-validation-error.svg?url"
          alt="error"
        >
      </button>
    </div>
    <NnwVariantsEducationTable
      :in-modal="true"
    />
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n();

const emit = defineEmits(['close']);
const closeModal = () => emit('close');
</script>
