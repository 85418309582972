<template>
  <div class="footer-layout main-layout">
    <div class="footer-layout__socials-client">
      <FooterSocialMediaIcons />
      <div>
        <button
          v-if="!props.panel"
          class="button button--secondary-outline button--radius button--menu-link"
          data-cy="cp-login-footer-button"
          @click="handleRedirectToLogin"
        >
          <span class="text-bold">{{ t("button.clientPanel") }}</span>
        </button>
      </div>
    </div>
    <div
      v-if="!(assignedAgentStore.agent?.id && assignedAgentStore.agent.hide_address)"
      class="footer-container__contact-full"
    >
      <span class="contact-full__name text-big text-bold display-block margin-bottom-1">
        {{ displayedCompanyName }}
      </span>
      <p>
        <span v-html="displayedCompanyData" />
        <br>
        <a :href="`tel:${displayedCompanyPhone}`" class="text-secondary text-no-decoration">
          {{ displayedCompanyPhone }}
        </a>
        <br>
        <a :href="`mailto:${displayedCompanyEmail}`" class="text-secondary text-no-decoration">
          {{ displayedCompanyEmail }}
        </a>
      </p>
    </div>

    <div
      class="footer-container__menu footer-container__menu--primary"
      :class="{
        'footer-container__menu--hide-companydata':
          assignedAgentStore.agent?.id && assignedAgentStore.agent.hide_address,
      }"
    >
      <span class="menu__title text-big text-bold display-block margin-bottom-05">
        {{ t("common.aboutUs") }}
      </span>
      <div v-for="menuLink in menuAboutUs" :key="menuLink.id">
        <RouterLink
          v-if="menuLink.routerLink"
          :to="menuLink.to"
          class="footer-container__menu--link"
          :data-cy="`footer-link-${menuLink.id}`"
        >
          {{ menuLink.title }}
        </RouterLink>
        <a
          v-if="!menuLink.routerLink"
          :href="menuLink.to"
          target="_blank"
          class="footer-container__menu--link"
        >
          {{ menuLink.title }}
        </a>
      </div>
    </div>

    <div
      class="footer-container__menu footer-container__menu--secondary"
      :class="{
        'footer-container__menu--hide-companydata':
          assignedAgentStore.agent?.id && assignedAgentStore.agent.hide_address,
      }"
    >
      <span class="menu__title text-big text-bold display-block margin-bottom-05">
        {{ t("common.insurances") }}
      </span>

      <div v-for="menuLink in categories.filter((cat) => cat.active)" :key="menuLink.id">
        <RouterLink
          :to="menuLink.to"
          class="footer-container__menu--link"
          :data-cy="`footer-link-2${menuLink.id}`"
        >
          {{ menuLink.title }}
        </RouterLink>
      </div>
      <div v-for="menuLink in menuInsurances" :key="menuLink.id">
        <RouterLink
          :to="menuLink.to"
          class="footer-container__menu--link"
          :data-cy="`footer-link-2${menuLink.id}`"
        >
          {{ menuLink.title }}
        </RouterLink>
      </div>
    </div>

    <div
      v-if="!(assignedAgentStore.agent?.id && assignedAgentStore.agent.hide_address)"
      class="footer-container__contact-min"
    >
      <a
        :href="`tel:${displayedCompanyHotLine}`"
        class="text-bigger text-extra-bold display-block margin-bottom-05 text-secondary text-no-decoration"
      >
        {{ displayedCompanyHotLine }}
      </a>
      <a
        :href="`mailto:${displayedCompanyEmail}`"
        class="text-secondary text-no-decoration text-small text-bold letter-spacing-6-percent"
      >
        {{ displayedCompanyEmail }}
      </a>
    </div>

    <div
      class="footer-container__logo"
      :class="{
        'footer-container__logo--hide-companydata':
          assignedAgentStore.agent?.id && assignedAgentStore.agent.hide_address,
      }"
    >
      <RouterLink
        v-if="
          !(
            assignedAgentStore.agent?.id &&
            (assignedAgentStore.agent.hide_all_logotype ||
              assignedAgentStore.agent.hide_footer_logotype)
          )
        "
        to="/"
      >
        <img
          v-if="
            assignedAgentStore.agent?.id &&
              assignedAgentStore.agent.show_partner_logotype &&
              assignedAgentStore.agent.image.length
          "
          :src="assignedAgentStore.agent.image"
          alt="logo"
          title="logo"
          loading="lazy"
        >

        <picture v-else>
          <source srcset="/assets/img/logo_footer.webp" type="image/webp">
          <img src="/assets/img/logo_footer.png" alt="logo" title="logo" loading="lazy">
        </picture>
      </RouterLink>
    </div>
  </div>
  <a :href="`tel:${companyData.customer_phone}`" class="floating-action-button">
    <img src="@/assets/img/icons/call-calling.svg?url" alt="">
  </a>
</template>

<script setup lang="ts">
import { useInsurancesStore } from '~/store/insurances';
import { useAssignedAgentStore } from '~/store/assigned-agent';
import { useCmsStore } from '~/store/cms-store';
import type { CmsDaneFirmyContent, CmsPolitykaPrywatnosciContent } from '~/types/cms';

const companyData = useCmsStore().getData('dane-firmy') as CmsDaneFirmyContent;
const ppData = useCmsStore().getData('polityka-prywatnosci') as CmsPolitykaPrywatnosciContent;

const assignedAgentStore = useAssignedAgentStore();
const insurancesStore = useInsurancesStore();
const categories = computed(() => insurancesStore.getCategories);

const router = useRouter();
const { t } = useI18n();

const props = defineProps({
  panel: Boolean,
  default: () => false,
});

const menuAboutUs = computed(() => [
  { id: 2, title: t('common.regulations'), to: '/regulaminy', routerLink: true },
  {
    id: 3,
    title: t('common.privacyPolicy'),
    to: ppData.file || '#',
    routerLink: false,
  },
  { id: 4, title: t('common.contact'), to: t('pages.contact'), routerLink: true },
]);

const menuInsurances = ref([
  { id: 3, title: 'Zgłoś zdarzenie', to: t('pages.reportEvent') },
]);

const handleRedirectToLogin = () => {
  router.push(t('pages.clientLogin'));
};

// ---------------------------------------
// AGENT/UNILINK DISPLAYED DATA
// ---------------------------------------

const displayedCompanyName = computed(() => {
  if (assignedAgentStore.agent?.show_partner_contact_data) {
    return assignedAgentStore.agent.contact_data.agent_company;
  } else {
    return companyData.company_name;
  }
});

const displayedCompanyData = computed(() => {
  if (assignedAgentStore.agent?.show_partner_contact_data) {
    const apartmentNr = assignedAgentStore.agent.contact_data.agent_apartment_number
      ? `/${assignedAgentStore.agent.contact_data.agent_apartment_number}`
      : '';
    return `${assignedAgentStore.agent.contact_data.agent_street} ${assignedAgentStore.agent.contact_data.agent_building_number}${apartmentNr}<br> ${assignedAgentStore.agent.contact_data.agent_postal_code} ${assignedAgentStore.agent.contact_data.agent_city}`;
  } else {
    return companyData.content;
  }
});

const displayedCompanyHotLine = computed(() => {
  if (assignedAgentStore.agent?.show_partner_contact_data) {
    return assignedAgentStore.agent.contact_data.agent_hotline;
  } else {
    return companyData.customer_phone;
  }
});

const displayedCompanyPhone = computed(() => {
  if (assignedAgentStore.agent?.show_partner_contact_data) {
    return assignedAgentStore.agent.contact_data.agent_office_phone;
  } else {
    return companyData.company_phone;
  }
});

const displayedCompanyEmail = computed(() => {
  if (assignedAgentStore.agent?.show_partner_contact_data) {
    return assignedAgentStore.agent.contact_data.agent_email;
  } else {
    return companyData.company_email;
  }
});
</script>
