export const isShowModal = reactive({
  cpDeleteUser: false,
  nwwSchoolVariantsTable: false,
  agentAliasInfo: false,
})

export const showModal = (key: 'contact' | 'cpDeleteUser' | 'nwwSchoolVariantsTable' | 'agentAliasInfo') => {
  isShowModal[key] = true
}
export const closeModal = (key: 'contact' | 'cpDeleteUser' | 'nwwSchoolVariantsTable' | 'agentAliasInfo') => {
  isShowModal[key] = false
}
