import { acceptHMRUpdate, defineStore } from 'pinia'

interface ErrorFromRequest {
  status: number | null
  statusText: string
  message: string
  errors: object
  type: string
}

interface ErrorFromValidation {
  isError: boolean
  type: string
}

export interface ErrorState {
  errorFromVs: ErrorFromValidation
  errorFromZipCode: ErrorFromValidation[]
  errorFromReq: ErrorFromRequest
}

export const useErrorStore = defineStore('error', {
  state(): ErrorState {
    return {
      errorFromVs: {
        isError: false,
        type: '',
      },
      errorFromZipCode: [{
        isError: false,
        type: '',
      }],
      errorFromReq: {
        status: null,
        statusText: '',
        message: '',
        errors: {},
        type: '',
      },
    }
  },
  actions: {
    setErrorVs(error) {
      this.errorFromVs = error
    },
    pushErrorZipCode(error) {
      this.errorFromZipCode.push(error)
    },
    filterErrorZipCode(id) {
      this.errorFromZipCode = this.errorFromZipCode.filter(v => v.isError === true && v.type !== `${id}-not-found`)
    },
    setErrorReq(error) {
      this.errorFromReq = error
    },
  },
  getters: {
    getErrorFromVs: (state: ErrorState) => state.errorFromVs && state.errorFromVs,
    getErrorFromZipCode: (state: ErrorState) => state.errorFromZipCode && state.errorFromZipCode,
    getErrorFromReq: (state: ErrorState) => state.errorFromReq && state.errorFromReq,
  },
  persist: false,
})

if (import.meta.hot) { import.meta.hot.accept(acceptHMRUpdate(useErrorStore, import.meta.hot)) }
